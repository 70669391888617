import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { authState } from "../../state/auth.state";
import { useTitle } from "../../hooks/useTitle.hook";
import LanguagePicker from "../../components/language-picker/language-picker.component";
import UploadToggleModels from "../../components/upload-toggle-models/upload-toggle-models.component";
import { useDatabaseUser } from "../../hooks/database-user.hook";
import { ConversionModels } from "../../types";
import toast from "react-hot-toast";

const SettingsPage = () => {
  useTitle("Settings");
  const [language, setLanguage] = useState("en");
  const [model, setModel] = useState("default");
  const { user } = useCombinedAuthHook();
  const { databaseUser, updateUser } = useDatabaseUser();

  useResetScroll();
  useRecoilValue(authState);

  useEffect(() => {
    if (!databaseUser) {
      return;
    }

    setLanguage(getDefaultLanguageFromDatabase());
    setModel(getDefaultModelFromDatabase());
  }, [databaseUser]);

  const getDefaultLanguageFromDatabase = () => {
    return databaseUser?.defaultLanguage || "en";
  };

  const getDefaultModelFromDatabase = () => {
    return databaseUser?.defaultModel || "default";
  };

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang);
  };

  const handleModelChange = (newModel: string) => {
    setModel(newModel);
  };

  const saveChanges = async () => {
    const updated = await updateUser(
      {
        defaultLanguage: language,
        defaultModel:
          model === "visuals"
            ? ConversionModels.Visuals
            : ConversionModels.Default,
      },
      user.uid
    );

    if (updated) {
      toast.success("The default preferences were updated successfully!");
    } else {
      toast.error(
        "There was a problem while updating the default preferences."
      );
    }
  };

  return (
    <div className="relative min-h-full max-h-full">
      <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
        Settings
      </h1>
      <div className="p-8 w-full">
        <div className="max-w-md">
          <div className="mb-4">
            <label
              htmlFor="language"
              className="block text-gray-700 text-sm font-semibold mb-2"
            >
              Default PDF language
            </label>
            <LanguagePicker
              selectedLanguage={language}
              changeSelectedLanguage={handleLanguageChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="language"
              className="block text-gray-700 text-sm font-semibold mb-2"
            >
              Default Extraction Model
            </label>
            <div className="relative w-full">
              <UploadToggleModels
                selectedLanguage={language}
                selectedModel={model}
                changeSelectedModel={handleModelChange}
              />
              {language !== "en" && model === "visuals" && (
                <p className="mt-2 text-gray-700 text-sm">
                  Have in mind, that the visual information would be read out in
                  English.
                </p>
              )}
            </div>
          </div>
          <button
            onClick={saveChanges}
            className="mt-5 w-full bg-slate-600 text-white py-2 rounded-md hover:bg-slate-700 focus:outline focus:outline-slate-400 focus:outline-offset-1 focus:transition focus:duration-75 transition duration-200"
          >
            Save Settings
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
