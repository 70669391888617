import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Notification, {
  NotificationProps,
} from "../../components/notification/notification.component";
import { auth, googleProvider } from "../../firebase";
import { useDatabaseUser } from "../../hooks/database-user.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { authState } from "../../state/auth.state";

const AuthenticatePage = () => {
  const setAuthUser = useSetRecoilState(authState);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false); // New state variable
  const [errorMessage, setErrorMessage] = useState<
    NotificationProps | undefined
  >(undefined);
  const { updateUser } = useDatabaseUser();
  const navigate = useNavigate();

  useResetScroll();

  useTitle("Sign Up");

  useEffect(() => {
    return () => setErrorMessage(undefined);
  }, []);

  const handleAuthSuccess = (user: any) => {
    setAuthUser({
      uid: user.uid,
      email: user.email!,
      name: user.displayName ?? "",
      photo: user.photoURL ?? "",
    });

    navigate("/app");
  };

  const handleGoogleSignIn = async () => {
    try {
      const response = await signInWithPopup(auth, googleProvider);
      setAuthUser({
        uid: response.user.uid,
        email: response.user.email!,
        name: response.user.displayName ?? "",
        photo: response.user.photoURL ?? "",
      });
      handleAuthSuccess(response.user);
    } catch (error: any) {
      setErrorMessage({
        type: "Error",
        message: mapAuthCodeToMessage(error.code),
      });
    }
  };

  const handleEmailAuth = async (e: any) => {
    e.preventDefault();
    try {
      let user;

      if (isSignUp) {
        const response = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = response.user;
        await updateProfile(user, { displayName: name });
        await updateUser(
          {
            name: name,
            email: user.email!,
            photo: user.photoURL ?? "",
            stripeSessionId: "",
          },
          user.uid
        );
      } else {
        const response = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = response.user;
      }

      setAuthUser({
        uid: user.uid,
        email: user.email!,
        name: user.displayName ?? "",
        photo: user.photoURL ?? "",
      });

      handleAuthSuccess(user);
    } catch (error: any) {
      setErrorMessage({
        type: "Error",
        message: mapAuthCodeToMessage(error.code),
      });
    }
  };

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage({
        type: "Success",
        message: "Password reset email sent successfully.",
      });
      setIsForgotPassword(false);
    } catch (error: any) {
      setErrorMessage({
        type: "Error",
        message: mapAuthCodeToMessage(error.code),
      });
    }
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
    setErrorMessage(undefined);
  };

  const mapAuthCodeToMessage = (authCode: string): string => {
    switch (authCode) {
      case "auth/invalid-password":
        return "The password is invalid.";
      case "auth/invalid-email":
        return "The email is invalid.";
      case "auth/invalid-credential":
        return "The email/password combination is wrong.";
      case "auth/email-already-exists":
        return "This email already exists.";
      case "auth/user-not-found":
        return "No user found with this email.";
      case "auth/too-many-requests":
        return "The account was temporarily locked due to too many failed attempts. Please, try again later.";
      default:
        return "An error occurred. Please try again.";
    }
  };

  return (
    <div className="flex w-full h-screen mx-auto rounded-2xl shadow-md">
      {/* <div className="w-0 md:w-1/2 bg-[#9958e8]"> */}
      <div className="w-0 md:w-1/2 bg-gradient-to-r from-[#9958e8] to-[#ac58e8]">
        <img
          src="/login_hero.jpg"
          alt="Person listening to smartphone using AirPods"
          className="object-cover w-full h-full"
        />
      </div>

      <div className="w-full md:w-1/2 flex flex-col justify-center items-center bg-white">
        <form
          onSubmit={isForgotPassword ? handleForgotPassword : handleEmailAuth}
          className="w-2/3 max-w-md"
        >
          <h2 className="text-3xl font-bold mb-8 text-gray-800">
            {isForgotPassword
              ? "Reset Your Password"
              : isSignUp
              ? "Create a free ReadPDF.io account now!"
              : "Sign In"}
          </h2>

          {!!errorMessage && (
            <Notification
              message={errorMessage.message}
              type={errorMessage.type}
            ></Notification>
          )}

          {isForgotPassword ? (
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Enter your email to reset your password.
              </label>
              <input
                type="email"
                id="email"
                placeholder="Type in your email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-md focus:border-black focus:outline-none focus:ring-1 focus:ring-black"
              />
            </div>
          ) : (
            <>
              {isSignUp && (
                <div className="mb-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Type in your name..."
                    className="w-full px-4 py-2 border rounded-md focus:border-black focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
              )}

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Type in your email..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-md focus:border-black focus:outline-none focus:ring-1 focus:ring-black"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-md focus:border-black focus:outline-none focus:ring-1 focus:ring-black"
                />
              </div>
            </>
          )}

          <button
            type="submit"
            className="w-full py-2 px-4 bg-slate-600 text-white font-semibold rounded-md hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-black"
          >
            {isForgotPassword
              ? "Send Reset Email"
              : isSignUp
              ? "Sign Up"
              : "Sign In"}
          </button>
        </form>

        {!isForgotPassword && (
          <div className="w-2/3 max-w-md mb-4">
            <button
              onClick={handleGoogleSignIn}
              className="w-full bg-red-500 hover:bg-red-600 focus:ring-2 focus:ring-red-600 text-white py-2 rounded-lg mt-4"
            >
              Sign in with Google
            </button>
          </div>
        )}

        <div className="text-center">
          {!isForgotPassword && (
            <p className="text-center mt-4">
              {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
              <span
                className="text-blue-500 cursor-pointer hover:underline"
                onClick={() => setIsSignUp(!isSignUp)}
              >
                {isSignUp ? "Sign In" : "Sign Up"}
              </span>
            </p>
          )}
          <button
            onClick={toggleForgotPassword}
            className="text-sm text-blue-600 hover:underline mt-2"
          >
            {isForgotPassword ? "Back to Sign In" : "Forgot Password?"}
          </button>
          {isSignUp && !isForgotPassword && (
            <>
              <hr className="mt-10" />
              <p className="text-sm text-center mt-5">
                <Link
                  to="/terms-and-conditions"
                  className="text-blue-600 hover:underline"
                >
                  Terms and conditions
                </Link>{" "}
                apply.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthenticatePage;
