// hooks/useFileUpload.ts

import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../state/auth.state";
import { globalFileUploadCount } from "../state/global-file-upload.state";
import { showAuthModal } from "../state/showAuthModal.state";
import { USER_ID_HEADER } from "../utils/constants";
import { useCombinedAuthHook } from "./combined-auth.hook";

const useFileUpload = () => {
  const { databaseUser } = useCombinedAuthHook();
  const authUser = useRecoilValue(authState);
  const setShowModal = useSetRecoilState(showAuthModal);
  const setFileUploadCount = useSetRecoilState(globalFileUploadCount);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleWindowDragEnd = () => {
      setIsDragging(false);
    };

    window.addEventListener("dragend", handleWindowDragEnd);
    window.addEventListener("dragleave", handleWindowDragEnd);
    window.addEventListener("drop", handleWindowDragEnd);

    return () => {
      window.removeEventListener("dragend", handleWindowDragEnd);
      window.removeEventListener("dragleave", handleWindowDragEnd);
      window.removeEventListener("drop", handleWindowDragEnd);
    };
  }, []);

  const triggerFileUpload = () => {
    fileInputRef.current!.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.length
      ? event.target.files[0]
      : null;

    if (!selectedFile) {
      return;
    }

    await checkIfAuthenticatedAndProcessFile(selectedFile);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const droppedFile = event.dataTransfer.files[0];

    if (!droppedFile) {
      return;
    }

    fileInputRef.current!.files = event.dataTransfer.files;
    await checkIfAuthenticatedAndProcessFile(droppedFile);
  };

  const uploadFileAndGetFileData = async (
    file: File,
    language: string,
    model: string
  ) => {
    const CHUNK_SIZE = 1024 * 1024 * 5; // 5MB
    let offset = 0;

    while (offset < file.size) {
      const chunk = file.slice(offset, offset + CHUNK_SIZE);
      const formData = new FormData();
      formData.append("file", chunk);
      formData.append("offset", `${offset}`);
      formData.append("fileName", file.name);
      formData.append("language", language);
      formData.append("userId", authUser.uid);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_APPLICATION_URL}/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          console.error(`Server error: ${response.statusText}`);
          throw new Error(response.statusText);
        }

        const result = await response.json();

        if (result.success) {
          offset += CHUNK_SIZE;
          updateProgressBar(offset, file.size);
        } else {
          throw new Error(
            "Upload failed. Please, try again later or get in touch with the support."
          );
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        throw new Error("Error while uploading.");
      }
    }

    if (offset >= file.size) {
      hideProgressBar();
      await new Promise((res) => setTimeout(() => res(null), 500));
      return await completeUploadAndGetId(model);
    }
  };

  const updateProgressBar = (offset: number, total: number) => {
    const percent = Math.min(Math.round((offset / total) * 100), 100);
    progressBarRef.current!.innerText = `Upload progress: ${percent}%`;
    progressBarRef.current!.style.width = percent + "%";
  };

  const hideProgressBar = () => {
    progressBarRef.current!.setAttribute("hidden", "");
  };

  const showProgressBar = () => {
    progressBarRef.current!.removeAttribute("hidden");
  };

  const completeUploadAndGetId = async (model: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_APPLICATION_URL}/complete-upload`,
        {
          headers: {
            [USER_ID_HEADER]: authUser?.uid,
            session_token: databaseUser.stripeSessionId,
            model: model,
          },
          method: "POST",
        }
      );
      const result = await response.json();

      if (result.success) {
        return result.fileId;
      } else {
        toast.error("Upload failed");
        throw new Error("Upload failed");
      }
    } catch (e) {
      console.error(e);
      return Promise.reject("Error while trying to fetch file data.");
    }
  };

  const checkIfAuthenticatedAndProcessFile = async (
    file: File,
    lang?: string,
    model?: string
  ) => {
    if (!authUser?.uid) {
      setShowModal(true);
      return;
    }

    showProgressBar();

    try {
      const fileId = await uploadFileAndGetFileData(
        file,
        lang ?? "en",
        model ?? "default"
      );

      if (fileId) {
        setFileUploadCount((curr) => curr + 1);
      }
    } catch (error) {
      toast.error(`${error}`);
      console.error("Error fetching file ID:", error);
    }
  };

  return {
    fileInputRef,
    progressBarRef,
    isDragging,
    setIsDragging,
    triggerFileUpload,
    handleFileChange,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    checkIfAuthenticatedAndProcessFile,
  };
};

export default useFileUpload;
