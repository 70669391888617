import { ChangeEvent, useEffect, useState } from "react";
import { CiCircleInfo } from "react-icons/ci";

import { Player as LottiePlayer } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import UploadConfirmationModalComponent from "../../components/upload-confirmation-modal/upload-confirmation-modal.component";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import useFileUpload from "../../hooks/useFileUpload.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { showUploadConfirmationModalState } from "../../state/showUploadConfirmationModal.state";
import { ActiveSubscriptionTypes } from "../../types";
import "./upload.styles.scss";

const UploadPage = () => {
  useTitle("Upload");
  const { subscriptionStatus, loading } = useCombinedAuthHook();
  const [showSubscribePrompt, setShowSubscribePrompt] = useState(false);
  const [showUploadConfirmationModal, setShowUploadConfirmationModal] =
    useRecoilState(showUploadConfirmationModalState);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);

  const {
    fileInputRef,
    progressBarRef,
    isDragging,
    setIsDragging,
    triggerFileUpload,
    handleFileChange,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    checkIfAuthenticatedAndProcessFile,
  } = useFileUpload();

  useResetScroll();

  useEffect(() => {
    if (!hasActiveSubscription) {
      setShowSubscribePrompt(true);
    } else {
      setShowSubscribePrompt(false);
    }
  }, [hasActiveSubscription]);

  useEffect(() => {
    if (loading) return;
    setHasActiveSubscription(
      ActiveSubscriptionTypes.includes(subscriptionStatus)
    );
  }, [subscriptionStatus, loading]);

  const settingsConsentAnd = (evt: ChangeEvent<any>, callback: () => any) => {
    const selectedFile = evt.target.files?.length ? evt.target.files[0] : null;

    if (!selectedFile) {
      return;
    }

    setFileToUpload(selectedFile);
    setShowUploadConfirmationModal(true);
  };

  const dropConsent = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsDragging(false);

    const droppedFile = evt.dataTransfer.files[0];
    if (!droppedFile) {
      return;
    }

    setFileToUpload(droppedFile);
    setShowUploadConfirmationModal(true);
  };

  const handleConsentDialogCloseAndTriggerUpload = (e: {
    lang: string | null;
    model: string | null;
  }) => {
    setShowUploadConfirmationModal(!showUploadConfirmationModal);

    const { lang, model } = e;
    if (!lang || !model) {
      return;
    }

    checkIfAuthenticatedAndProcessFile(fileToUpload as File, lang, model);
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 ${
          isDragging ? "" : "hidden"
        }`}
      ></div>
      <h1 className="bold text-center text-xl">Upload a PDF</h1>

      {showSubscribePrompt && (
        <div className="text-center py-4 lg:px-4">
          <div
            className="p-2 bg-blue-200 items-center text-gray-600 leading-none rounded-full flex lg:inline-flex shadow-sm px-5"
            role="alert"
          >
            <div className="relative -top-0.5 mr-3">
              <CiCircleInfo className="w-4 h-4 mt-1 ml-2" />
            </div>
            <span className="mr-2 text-left flex-auto">
              Only the first page would be converted while in trial.{" "}
              <Link className="underline" to="/app/pricing">
                Subscribe
              </Link>{" "}
              to convert the whole document.
            </span>
          </div>
        </div>
      )}

      <div className="w-11/12 ml-5 mt-5"></div>
      <div
        className="mb-9"
        onClick={(e) => triggerFileUpload()}
        onDragOver={(e) => handleDragOver(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDrop={(e) => dropConsent(e)}
      >
        <div
          className={`dash-pattern w-11/12 h-[75vh] border-2 border-dashed rounded-lg flex items-center justify-center m-8 p-8 cursor-pointer ${
            isDragging
              ? "relative z-[60] bg-gray-200 border-gray-500"
              : "bg-white hover:bg-[#fbfbfb] border-gray-300"
          } ${!hasActiveSubscription ? "cursor-not-allowed" : ""}`}
        >
          <div>
            <LottiePlayer
              src="/1729870294048.json"
              className="player"
              loop
              autoplay
            />
            <h3 className="text-center text-gray-800 text-2xl">
              {isDragging ? (
                <span>Drop here</span>
              ) : (
                <span>
                  Drag and Drop PDF Here
                  <br />
                  or Click to Upload
                </span>
              )}
            </h3>
          </div>
          <input
            type="file"
            id="pdfUpload"
            className="hidden-input"
            accept=".pdf"
            ref={fileInputRef}
            onChange={(evt) =>
              settingsConsentAnd(evt, () => handleFileChange(evt))
            }
          />
        </div>
      </div>
      <section
        id="progress-bar"
        className="text-center my-8"
        ref={progressBarRef}
        hidden
      >
        <div className="relative w-full h-6 bg-gray-200 rounded overflow-hidden">
          <div
            id="progress-bar-update"
            className="h-full bg-blue-500"
            style={{ width: "0%" }}
          >
            0%
          </div>
        </div>
        <span>Preparing your file for listening...</span>
      </section>

      <UploadConfirmationModalComponent
        show={showUploadConfirmationModal}
        onClose={handleConsentDialogCloseAndTriggerUpload}
      />
    </>
  );
};

export default UploadPage;
