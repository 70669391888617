import { useEffect, useState } from "react";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import LanguagePicker from "../language-picker/language-picker.component";
import UploadToggleModels from "../upload-toggle-models/upload-toggle-models.component";

type UploadConfirmationModalComponentProps = {
  show: boolean;
  onClose: (a?: any) => void;
};

const UploadConfirmationModalComponent = ({
  show,
  onClose,
}: UploadConfirmationModalComponentProps) => {
  const { databaseUser } = useCombinedAuthHook();

  const [language, setLanguage] = useState("en");
  const [model, setModel] = useState("default");

  useEffect(() => {
    if (!databaseUser) {
      return;
    }

    setLanguage(getDefaultLanguageFromDatabase());
    setModel(getDefaultModelFromDatabase());
  }, [databaseUser]);

  const getDefaultLanguageFromDatabase = () => {
    return databaseUser?.defaultLanguage || "en";
  };

  const getDefaultModelFromDatabase = () => {
    return databaseUser?.defaultModel || "default";
  };

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang);
  };

  const handleModelChange = (newModel: string) => {
    setModel(newModel);
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose({ lang: null, model: null });
      }
    };

    if (show) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [show, onClose]);

  const confirm = () => {
    const selectedLanguage = language ?? databaseUser.defaultLanguage ?? "en";
    const selectedModel = model ?? databaseUser.defaultModel ?? "default";
    onClose({ lang: selectedLanguage, model: selectedModel });
  };

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[52]">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold mb-4">
            Confirm Upload Settings
          </h2>
          <button
            onClick={() => onClose({ lang: null, model: null })}
            className="text-gray-500 hover:text-gray-800 h-fit"
          >
            &times;
          </button>
        </div>
        <div className="p-4 px-1">
          <div className="w-full">
            <div className="max-w-md">
              <div className="mb-4">
                <label
                  htmlFor="language"
                  className="block text-gray-700 text-sm font-semibold mb-2"
                >
                  What's the language in the PDF?
                </label>
                <LanguagePicker
                  selectedLanguage={language}
                  changeSelectedLanguage={handleLanguageChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="language"
                  className="block text-gray-700 text-sm font-semibold mb-2"
                >
                  What shall be extracted?
                </label>
                <div className="relative w-full">
                  <UploadToggleModels
                    selectedLanguage={language}
                    selectedModel={model}
                    changeSelectedModel={handleModelChange}
                  />
                  {language !== "en" && model === "visuals" && (
                    <p className="mt-2 text-gray-700 text-sm">
                      Have in mind, that the visual information would be read
                      out in English.
                    </p>
                  )}
                </div>
              </div>
              <button
                onClick={confirm}
                disabled={!databaseUser}
                className="mt-5 w-full bg-slate-600 text-white py-2 rounded-md hover:bg-slate-700 focus:outline focus:outline-slate-400 focus:outline-offset-1 focus:transition focus:duration-75 transition duration-200"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadConfirmationModalComponent;
